import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '../engine-ui/Tooltip'
import Button from '../engine-ui/Button'
import DataTable from '../engine-ui/DataTable'
import Pagination from '../engine-ui/Pagination'

export default function JobsDataTable({
  data,
  totalRecords,
  rowsPerPage,
  currentPage,
  onPageChange,
  onDiscard,
  onRunNow,
  onSort,
  onAttachmentEditClick,
  onEmailTitleClick,
  superAdmin,
}) {
  const renderHead = () => (
    <tr className="table-head-row">
      <th>Job-ID</th>
      <th>E-Mail Betreff</th>
      <th>
        Geplant für den Versand am
        <span onClick={() => onSort()} className="icon-btn color-grey-med ms-2">
          <i className="fas fa-sort" />
        </span>
      </th>
      <th>Tatsächlicher Versand am</th>
      <th>Dokumente</th>
      <th>Aktionen</th>
    </tr>
  )

  const renderRow = (rowData, { index }) => (
    <tr key={index} className={`tab-table-row ${rowData.table_tr_class}`}>
      <td>
        {superAdmin ? (
          <a
            href={`/admin/jobs/applications/mietright/jobs/${rowData.active_job_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowData.id}
          </a>
        ) : (
          rowData.id
        )}
      </td>
      <td>
        {rowData.email_body ? (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              onEmailTitleClick(rowData.email_title, rowData.email_body)
            }}
          >
            {rowData.email_title}
          </a>
        ) : (
          rowData.email_title
        )}
      </td>
      <td>
        <Tooltip text={rowData.processed_at_in_words} placement="top">
          {rowData.processed_at}
        </Tooltip>
      </td>
      <td>
        <Tooltip text={rowData.finished_at_in_words} placement="top">
          {rowData.finished_at}
        </Tooltip>
      </td>
      <td className="jobs-table__cell">
        {rowData.documents &&
          rowData.documents.map((doc, docIndex) => (
            <div key={docIndex}>
              <span>{doc.document_name}</span>
              <span> </span>
              <span>
                <a
                  href="#"
                  target="_self"
                  rel="noreferrer"
                  onClick={() => onAttachmentEditClick(doc)}
                >
                  <i className="fas fa-pencil-alt color--grey"></i>
                </a>
              </span>
            </div>
          ))}
      </td>
      <td className="ps-0 jobs-table__cell pe-2">
        {rowData.perform_actions && (
          <>
            <Tooltip text="Run Now" placement="top">
              <Button
                value={rowData.id}
                onClick={() => onRunNow(rowData.id)}
                className="btn py-0 px-1"
              >
                <i className="fas fa-play jobs-table__action-icon" />
              </Button>
            </Tooltip>
            <Tooltip text="Discard" placement="top">
              <Button
                value={rowData.id}
                onClick={() => onDiscard(rowData.id)}
                className="btn py-0 px-1"
              >
                <i className="fas fa-trash-alt jobs-table__action-icon" />
              </Button>
            </Tooltip>
          </>
        )}
      </td>
    </tr>
  )

  const renderPagination = () => (
    <Pagination
      totalRecords={totalRecords}
      rowsPerPage={rowsPerPage}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  )

  return (
    <DataTable
      rows={data}
      renderRow={renderRow}
      renderHead={renderHead}
      renderPagination={renderPagination}
      className="table table-striped table-dark-engine-funnel"
    />
  )
}

JobsDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onRunNow: PropTypes.func.isRequired,
  onAttachmentEditClick: PropTypes.func.isRequired,
  onEmailTitleClick: PropTypes.func.isRequired,
  superAdmin: PropTypes.bool,
}

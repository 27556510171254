import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import AsyncCreateableSelect from 'react-select/async-creatable'
import { handleInputChange } from 'react-select/src/utils'

export default function Autocomplete(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [displayedOptions, setDisplayedOptions] = useState([])
  const [selectedOption, setSelectionOption] = useState(null)
  const [search, setSearch] = useState('')

  const {
    options,
    requestId,
    onChangeBatch,
    disabled,
    className,
    value,
    token,
  } = props

  // lodash 4.17.15 on the global scope
  const url = (_.find(options, ['text', 'url']) || {})['value'].replace(
    '$requestId',
    requestId,
  )
  const placeholder = (_.find(options, ['text', 'placeholder']) || {})['value']
  const displayAttribute = (_.find(options, ['text', 'displayAttribute']) ||
    {})['value']

  const fieldsAndValues = options
    .filter((option) => option['text'] == 'suppliParams')
    .map((option) => option['value'].split(','))

  useEffect(() => {
    setSearch(value)
    getOptions(value, () => {}, true)
  }, [value])

  const arrayToObject = (item) => {
    return fieldsAndValues.reduce((acc, current) => {
      if (!item[current[1]]) {
        return acc
      }

      acc[current[0]] = item[current[1]]
      return acc
    }, {})
  }

  const filterFields = (selected) => {
    // this filtering is to prevent sending auto generated to upper components
    const fields = fieldsAndValues.map((fv) => fv[0])

    return _.pick(selected, fields)
  }

  const getOptions = useCallback(
    (query, callback, first = false) => {
      if (!url) return

      setIsLoading(true)

      const headers = { token: token }

      fetch(`${url}?q=${encodeURIComponent(query)}`, { headers: headers })
        .then((resp) => resp.json())
        .then(({ items }) => {
          let newDisplayedOptions
          if (!items.length) {
            newDisplayedOptions = [
              {
                [displayAttribute]: query,
              },
            ]
            setDisplayedOptions(newDisplayedOptions)
            callback(newDisplayedOptions)
          } else {
            newDisplayedOptions = items.map(arrayToObject)
            setDisplayedOptions(newDisplayedOptions)
            callback(newDisplayedOptions)
          }

          return newDisplayedOptions
        })
        .then((newDisplayedOptions) => {
          if (first) {
            setSelectionOption(
              newDisplayedOptions.find(
                (option) => option[displayAttribute] === value,
              ),
            )
          }
        })
        .finally(() => setIsLoading(false))
    },
    [value],
  )

  const handleChange = (selected, event) => {
    if (event.action === 'clear') {
      const emptyOption = {
        [displayAttribute]: '',
      }

      onChangeBatch && onChangeBatch(emptyOption)
      setSelectionOption(emptyOption)
    } else {
      const filteredSelected = filterFields(selected)
      setSelectionOption(filteredSelected)
      onChangeBatch && onChangeBatch(filteredSelected)
    }
  }

  const handleBlur = () => {
    onChangeBatch &&
      onChangeBatch({
        [displayAttribute]: search,
      })
  }

  const handleInputChange = (_value) => {
    setSearch(_value)
  }

  const handleKeyDown = (e) => {
    if (e.target.value.length > 0) {
      e.stopPropagation()
    }
  }

  return (
    <AsyncCreateableSelect
      isClearable
      className={`${className} autocomplete__select`}
      isLoading={isLoading}
      value={selectedOption}
      cacheOptions
      loadOptions={getOptions}
      defaultOptions
      getOptionLabel={(option) => option[displayAttribute]}
      getOptionValue={(option) => option[displayAttribute]}
      onChange={handleChange}
      isDisabled={disabled}
      placeholder={placeholder}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      backspaceRemovesValue={false}
    />
  )
}

Autocomplete.displayName = 'Autocomplete'

Autocomplete.propTypes = {
  options: PropTypes.array,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChangeBatch: PropTypes.func,
  value: PropTypes.string,
  token: PropTypes.string,
}

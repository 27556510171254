import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../../engine-ui/TextInput'
import { Label, InputBox } from './styles/documentAttr'

export default function NameField({ onChange, formValues }) {
  const format = `.${formValues.extension.value}`

  const fileName = formValues.filename.value.includes(format)
    ? formValues.filename.value.replace(format, '')
    : formValues.filename.value

  const [errorMessage, setErrorMessage] = useState('')
  const handleChange = (e) => {
    const newValue = e.value

    if (newValue.includes('/')) {
      setErrorMessage("Filename cannot contain '/'")
    } else if (newValue.includes('.')) {
      setErrorMessage("Filename cannot contain '.'")
    } else if (/\s/.test(newValue)) {
      setErrorMessage('Filename cannot contain whitespaces')
    } else {
      setErrorMessage('')
      onChange({
        name: 'filename',
        value: newValue,
      })
    }
  }

  return (
    <>
      <Label>Name: </Label>
      <InputBox>
        <div style={{ position: 'relative', width: '100%' }}>
          {errorMessage && (
            <div
              style={{
                position: 'absolute',
                color: 'red',
                fontSize: '15px',
                top: '-24px',
                left: '0',
              }}
            >
              {errorMessage}
            </div>
          )}
          <div className="field form-group input-group">
            <TextInput
              name={'filename'}
              className="form-control"
              value={fileName}
              onChange={handleChange}
            />
            <div className="input-group-append">
              <span className="input-group-text">{format}</span>
            </div>
          </div>
        </div>
      </InputBox>
    </>
  )
}

NameField.propTypes = {
  onChange: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
}

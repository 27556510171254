import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DataTable extends Component {
  static propTypes = {
    rows: PropTypes.array.isRequired,
    head: PropTypes.array,
    columns: PropTypes.number,
    className: PropTypes.string,
    renderRow: PropTypes.func.isRequired,
    renderHead: PropTypes.func,
    renderPagination: PropTypes.func,
    fieldsOrder: PropTypes.array,
    courtIds: PropTypes.array,
  }

  static defaultProps = {
    columns: 0,
    renderHead: () => {},
    renderPagination: () => {},
  }

  render() {
    const {
      rows,
      head,
      columns,
      className,
      renderRow,
      renderHead,
      renderPagination,
      fieldsOrder,
      courtIds,
    } = this.props

    const theadMarkup = renderHead(head, { fieldsOrder })
    const tbodyMarkup = rows.map((row, index) =>
      renderRow(row, { index, fieldsOrder, courtIds }),
    )

    return (
      <div className="data-table-wrapper">
        <table className={className}>
          <thead>{theadMarkup}</thead>
          <tbody>{tbodyMarkup}</tbody>
        </table>
        {renderPagination && renderPagination()} {}
      </div>
    )
  }
}

import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  MouseEvent,
} from 'react'
import classNames from 'classnames'
import { ActivityLogContext } from './store'
import client from '../../../../client'
import Spinner from '../engine-ui/Spinner'

interface IProps {
  soft_deleted: boolean
  id: string
  subtype: subtype
}

export default function RestoreItemButton({
  soft_deleted,
  id,
  subtype,
}: IProps) {
  const loggableId = id.replace(/^\D+/g, '')

  const [isSoftDeleted, setIsRestored] = useState(soft_deleted)
  const [isLoading, setIsLoading] = useState(false)

  const { requestId, onItemMarkedRestore } = useContext(ActivityLogContext)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleRestoreClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    isSoftDeleted && restoreItemLog()
  }

  const restoreItemLog = () => {
    const RESTORE_URL = `/admin/v2/requests/${requestId}/feed_items/${loggableId}/restore?subtype=${subtype}`

    setIsLoading(true)

    client
      .post(RESTORE_URL)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'ok') {
          if (isMounted.current) {
            onItemMarkedRestore(id, false)
            setIsRestored(false)

            const event = new CustomEvent('todoRestored')
            document.dispatchEvent(event)
          }
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false)
        }
      })
  }

  if (isLoading) {
    return (
      <div className="log-item__icon-container">
        <Spinner />
      </div>
    )
  }

  const title =
    subtype === 'document_deleted'
      ? 'Document'
      : subtype === 'ticket_deleted'
        ? 'ToDo'
        : 'Item'

  return (
    <div className="log-item__icon-container" style={{ marginLeft: '-22px' }}>
      <i
        onClick={handleRestoreClick}
        className={classNames(
          'fa-trash-restore log-item__icon mark-action fas',
        )}
        title={`${title} wiederherstellen`}
      ></i>
    </div>
  )
}

import React from 'react'
import moment from 'moment'
import classNames from 'classnames'

import MarkItemButton from './MarkItemButton'
import RestoreItemButton from './RestoreItemButton'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const expandableTypes = [
  'email_log',
  'document',
  'note',
  'ticket',
  'admin_edited',
  'payment',
  'econsult_sent_invoice',
  'automatic_physical_mail',
  'bea_file',
  'advo_assist',
]

export default function LogItemHeader(props) {
  const {
    id,
    icon,
    type,
    subtype,
    title,
    details_1,
    details_2,
    agent,
    date,
    marked,
    soft_deleted,
  } = props.data
  const formattedDate = moment(date).locale('de').format('dd, DD.MM.YYYY HH:mm')

  const popover = (title) => {
    return function _popover(props) {
      return (
        <Popover body {...props}>
          {title}
        </Popover>
      )
    }
  }

  function detail(detail, className = 'log-item__text') {
    detail = detail ?? ''
    return (
      <OverlayTrigger placement="top" delay={100} overlay={popover(detail)}>
        <span className={classNames(className)}>{detail}</span>
      </OverlayTrigger>
    )
  }

  return (
    <div
      className={classNames('log-item__header', {
        'log-item__header--expandable': expandableTypes.includes(type),
      })}
    >
      <div
        className={classNames('log-item__icon-container', {
          [`log-item__bg-color--${type}`]: expandableTypes.includes(type),
          [`log-item__bg-color--email_log-${subtype}`]: type === 'email_log',
        })}
      >
        <i className={`${icon} log-item__icon`} />
      </div>
      <p
        title={title}
        className={classNames('log-item__text', {
          [`log-item__color--${type}`]: expandableTypes.includes(type),
          [`log-item__color--email_log-${subtype}`]: type === 'email_log',
          'log-item__text--white': !expandableTypes.includes(type),
        })}
      >
        {title}
      </p>
      {detail(
        details_1,
        classNames('log-item__text', {
          'log-item__text-long': !details_2,
        }),
      )}
      {details_2 && detail(details_2)}
      <p className="log-item__text log-item__agent">{agent}</p>
      <p
        className={classNames('log-item__text', 'log-item__date', {
          'log-item__text--white': !expandableTypes.includes(type),
        })}
      >
        {formattedDate}
      </p>
      <MarkItemButton id={id} marked={marked} />
      {soft_deleted && (
        <RestoreItemButton
          id={id}
          soft_deleted={soft_deleted}
          subtype={subtype}
        />
      )}
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'

import Checkbox from '../engine-ui/Checkbox'
import JobsDataTable from './JobsDataTable'
import client from '../../../../client'

function JobsTab(props) {
  const initialState = {
    jobs: {
      data: [],
      totalRecords: 0,
      rowsPerPage: 20,
    },
    currentPage: 1,
    includeFinished: false,
  }

  const [state, setState] = useState({ ...initialState })
  const [sortOrder, setSortOrder] = useState('asc')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [emailBody, setEmailBody] = useState('')
  const [emailTitle, setEmailTitle] = useState('')

  const handleEmailTitleClick = (emailTitle, emailBody) => {
    if (emailBody) {
      setEmailBody(emailBody)
      setEmailTitle(emailTitle)
      setIsModalOpen(true)
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setEmailBody('')
    setEmailTitle('')
  }

  const tabClick = () => {
    const listener = () => {
      setState((prevState) => ({
        ...prevState,
        includeFinished: false,
      }))

      fetchJobs(state.currentPage, state.jobs.rowsPerPage, false)
    }
    document.querySelector('#jobs-tab').addEventListener('click', listener)

    return () => {
      document.querySelector('#jobs-tab').removeEventListener('click', listener)
    }
  }

  const fetchJobs = (
    page = 1,
    rowsPerPage = 20,
    includeFinished = false,
    sortOrder = 'asc',
  ) => {
    const { requestId } = props

    client
      .get(
        `/admin/v2/requests/${requestId}/solid_queue_jobs?page=${page}&rows_per_page=${rowsPerPage}&include_finished=${includeFinished}&order_by=${sortOrder}`,
      )
      .then((response) => response.json())
      .then((data) =>
        setState((prevState) => ({
          ...prevState,
          jobs: {
            data: data.jobs.data,
            totalRecords: data.jobs.total_rows,
            rowsPerPage: rowsPerPage,
          },
          currentPage: page,
        })),
      )
  }

  const handleDiscard = (id) => {
    if (
      window.confirm(
        'Sind Sie sicher, dass Sie diesen Auftrag nicht bearbeiten wollen?',
      )
    ) {
      const { requestId } = props
      client
        .patch(`/admin/v2/requests/${requestId}/solid_queue_jobs/${id}/discard`)
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'ok') {
            fetchJobs(
              state.currentPage,
              state.jobs.rowsPerPage,
              state.includeFinished,
            )
          }
        })
    }
  }

  const handleRunNow = (id) => {
    if (
      window.confirm(
        'Sind Sie sicher, dass Sie diesen Auftrag sofort bearbeiten wollen?',
      )
    ) {
      const { requestId } = props
      client
        .patch(`/admin/v2/requests/${requestId}/solid_queue_jobs/${id}/run_now`)
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'ok') {
            fetchJobs(
              state.currentPage,
              state.jobs.rowsPerPage,
              state.includeFinished,
            )
          }
        })
    }
  }

  const handleCheckboxChange = () => {
    setState((prevState) => {
      const newIncludeFinished = !prevState.includeFinished
      fetchJobs(
        prevState.currentPage,
        prevState.jobs.rowsPerPage,
        newIncludeFinished,
      )
      return { ...prevState, includeFinished: newIncludeFinished }
    })
  }

  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    setSortOrder(newSortOrder)
    fetchJobs(
      state.currentPage,
      state.jobs.rowsPerPage,
      state.includeFinished,
      newSortOrder,
    )
  }

  const handleAttachmentEditClick = (document) => {
    V2.trigger('document.show', {
      url: document.edit_url,
      filename: document.document_name,
      documentId: document.document_id,
    })
  }

  useEffect(() => {
    tabClick()
  }, [])

  return (
    <>
      <div className="jobs-header">
        <span className="form_category__title">E-Mail Jobs</span>
        <div className="col-4">
          <Checkbox
            name="include_finished"
            id="include_finished"
            value={!state.includeFinished}
            onChange={handleCheckboxChange}
            className="form-check-input me-2 mt-1 icon-btn"
          />
          <label
            htmlFor="include_finished"
            className="form-check-label text-white mb-0"
          >
            Ausgeschlossen Versendet
          </label>
        </div>
      </div>
      <br />

      <JobsDataTable
        data={state.jobs?.data || []}
        totalRecords={state.jobs.totalRecords}
        rowsPerPage={state.jobs.rowsPerPage}
        currentPage={state.currentPage}
        onPageChange={(page) =>
          fetchJobs(page, state.jobs.rowsPerPage, state.includeFinished)
        }
        onDiscard={handleDiscard}
        onRunNow={handleRunNow}
        onSort={handleSort}
        onAttachmentEditClick={handleAttachmentEditClick}
        onEmailTitleClick={handleEmailTitleClick}
        superAdmin={props.superAdmin}
      />

      <Modal show={isModalOpen} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{emailTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-scrollable">
          <div dangerouslySetInnerHTML={{ __html: emailBody }} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleModalClose}
          >
            schließen
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default JobsTab
